import { Col, Modal, Row } from 'react-bootstrap';
import { CustomModal } from '../../components/styles/addEditDialogFormStyles';
import { DISCOUNT_PRODUCTS, DISCOUNT_VALUE_FIXED_AMOUNT, DISCOUNT_VALUE_PERCENTAGE } from '../../utils/constants';
import styled from 'styled-components';
import { TfiShoppingCartFull } from "react-icons/tfi";
import { TbShoppingCartCopy } from "react-icons/tb";
import { Colors } from '../../components/styles/colors';

const SelectDiscountType = (props) => {
    const { showDiscountTypeDialog, onClickSubmit, onClickCancel } = props;

    return <>
        <CustomModal show={showDiscountTypeDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{"Select Discount Type"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <DiscountListCol>
                            <ul>
                                <li>
                                    <button onClick={() => onClickSubmit(DISCOUNT_PRODUCTS, DISCOUNT_VALUE_PERCENTAGE)}>
                                        <i>
                                            <TfiShoppingCartFull />
                                        </i>
                                        <span>
                                            <h6>Product Percentage Discount</h6>
                                            <p>Offer percentage discount to products.</p>
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => onClickSubmit(DISCOUNT_PRODUCTS, DISCOUNT_VALUE_FIXED_AMOUNT)}>
                                        <i>
                                            <TbShoppingCartCopy/>
                                        </i>
                                        <span>
                                            <h6>Product Flat Discount</h6>
                                            <p>Offer flat discount on products.</p>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </DiscountListCol>
                    </Col>
                </Row>
            </Modal.Body>
        </CustomModal>

    </>
}

export default SelectDiscountType;

const DiscountListCol = styled.div`
    ul{
        padding:0;
        margin:0;
    }
    ul li{
        list-style: none;
        text-align: left;
        margin-bottom: 10px;

        &:last-child{
            margin-bottom: 0;
        }
    }
    button{
        width:100%;
        text-align: left;
        padding: 15px;
        border-radius: 6px;
        display:flex;
        align-items: center;
        background-color: ${Colors.LightGreyCOlor};
        color: ${Colors.blackColor};
        border:2px solid transparent;

        &:hover{
            border: 2px solid ${Colors.commonBorderColor};
            background-color: ${Colors.themeColorPrimary+"a1"};

            h6, p{
                color:${Colors.whiteColor};
            }
        }
    }
    i{
        background:red;
        width:60px;
        height:60px;
        display:flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 6px;
        background-color: ${Colors.themeColorPrimary};
    }
    svg{
        font-size: 30px;
        color:${Colors.whiteColor};
    }

    span, p{
        margin-bottom: 0;
    }
    h6{
        margin-bottom: 1px;
    }
`;