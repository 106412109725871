import { useReducer } from 'react';
import { INITIAL_ALERT_STATE } from '../../utils/constants'

const INITIAL_OPTIONS_DIALOG_STATE = {
    showAddEditDialog: false,
    optionsIndex: "",
}

const INITIAL_EDIT_OPTION_VALUE_DIALOG_STATE = {
    showAddEditOptionValue: false,
    isEdit: false,
    productOptionValue: "",
    optionsIndex: "",
    optionValuesIdx: ""
}

const INITIAL_EDIT_VARIANT_DIALOG_STATE = {
    showEditVariantProperties: false,
    variant: {}
}

const INITIAL_EDIT_OPTION_NAME_DIALOG_STATE = {
    showEditOptionName: false,
    productOptionName: "",
    optionsIndex: "",
}

const INITIAL_STATE = {
    productName: "",
    productDescription: "",
    productStatus: "Draft",

    productPrice: "",
    productDiscountedPrice: "",
    productCostPerItem: "",

    chargeTax: "N",
    hasOptions: "N",
    taxPercentage: "",
    trackQuantity: "Y",
    productQuantity: 0,
    productSKU: "",
    productBarcode: "",

    seoTitle: "",
    seoDescription: "",
    urlHandle: "",

    primaryImageAttachmentObj: {

    },
    selectedMediaImages: [],

    masterData: {},
    selectedVendor: {},
    selectedProductType: {},
    selectedProductGroups: [],
    selectedProductTags: [],

    productOptions: [],
    variants: {

    },

    showImageLibraryDialog: false,
    sourceType: "",
    isMultiSelect: false,

    udfValueMap: {},

    errors: {
        productName: "",
        productDescription: "",
        productStatus: "",

        productPrice: "",

        seoTitle: "",
        seoDescription: "",
        urlHandle: "",
    },
    apiErr: "",
    componentDidMount: "N"
}

const initialState = {
    ...INITIAL_STATE,

    isPageLoading: false,
    refreshFlag: false,

    //DIALOG
    addOptionsDialog: { ...INITIAL_OPTIONS_DIALOG_STATE },
    addEditOptionValueDialog: { ...INITIAL_EDIT_OPTION_VALUE_DIALOG_STATE },
    editOptionNameDialog: { ...INITIAL_EDIT_OPTION_NAME_DIALOG_STATE },
    editVariantPropertiesDialog: { ...INITIAL_EDIT_VARIANT_DIALOG_STATE },

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const productVariantFields = {
    productPrice: "",
    productDiscountedPrice: "",
    productCostPerItem: "",

    chargeTax: "N",
    taxPercentage: "",
    trackQuantity: "Y",
    productQuantity: 0,
    productSKU: "",
    productBarcode: "",
}

const createVariants = (optionValues, optionsIndex, productOptions) => {
    let variants = {};
    if (optionsIndex === 0) {
        optionValues.forEach((option) => {
            variants[option.feIndex] = {
                ...productVariantFields
            }
        })
    } else if (optionsIndex === 1) {
        let firstOptionValues = [...productOptions[0].optionValues];

        firstOptionValues.forEach((firstOption) => {
            variants[firstOption.feIndex] = {};
            optionValues.forEach((secondOption) => {
                variants[firstOption.feIndex][secondOption.feIndex] = {
                    ...productVariantFields
                }
            })
        })
    }
    return variants;
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_MASTER_AND_DETAILS": {
            const { productDetails, masterData } = action;
            if (productDetails.hasOptions === "N") {
                let projectDetailsState = {
                    productName: productDetails.productName,
                    productDescription: productDetails.productDescription,
                    productStatus: productDetails.productStatus,

                    productPrice: productDetails.productPrice,
                    productDiscountedPrice: productDetails.productDiscountedPrice,
                    productCostPerItem: productDetails.productCostPerItem,

                    chargeTax: productDetails.chargeTax,
                    hasOptions: productDetails.hasOptions,
                    taxPercentage: productDetails.taxPercentage,
                    trackQuantity: productDetails.trackQuantity,
                    productQuantity: productDetails.totalProductQuantity,
                    productSKU: productDetails.productSKU,
                    productBarcode: productDetails.productBarcode,

                    seoTitle: productDetails.seoTitle,
                    seoDescription: productDetails.seoDescription,
                    urlHandle: productDetails.urlHandle,

                    primaryImageAttachmentObj: {
                        productAttachmentId: productDetails.productAttachmentId,
                        productAttachmentPath: productDetails.productAttachmentPath,
                    },

                    selectedMediaImages: [...productDetails.mediaImages],

                    selectedVendor: {
                        vendorId: productDetails.vendorId,
                        vendorName: productDetails.vendorName,
                    },

                    selectedProductType: {
                        productTypeId: productDetails.productTypeId,
                        productTypeName: productDetails.productTypeName,
                    },

                    selectedProductGroups: [...productDetails.productGroups],

                    selectedProductTags: [...productDetails.productTags],
                    
                    udfValueMap: {...productDetails.udfValueMap},
                }

                return {
                    ...INITIAL_STATE,
                    alertDialog: { ...INITIAL_ALERT_STATE },
                    // refreshFlag:false, // Refresh is done so set it to false
                    componentDidMount: "Y",
                    isPageLoading: false,
                    masterData: masterData,
                    ...projectDetailsState
                }
            } else if (productDetails.hasOptions === "Y") {
                let projectDetailsState = {
                    productName: productDetails.productName,
                    productDescription: productDetails.productDescription,
                    productStatus: productDetails.productStatus,

                    hasOptions: productDetails.hasOptions,

                    seoTitle: productDetails.seoTitle,
                    seoDescription: productDetails.seoDescription,
                    urlHandle: productDetails.urlHandle,

                    primaryImageAttachmentObj: {
                        productAttachmentId: productDetails.productAttachmentId,
                        productAttachmentPath: productDetails.productAttachmentPath,
                    },

                    selectedMediaImages: [...productDetails.mediaImages],

                    selectedVendor: {
                        vendorId: productDetails.vendorId,
                        vendorName: productDetails.vendorName,
                    },

                    selectedProductType: {
                        productTypeId: productDetails.productTypeId,
                        productTypeName: productDetails.productTypeName,
                    },

                    selectedProductGroups: [...productDetails.productGroups],

                    selectedProductTags: [...productDetails.productTags],

                    udfValueMap: {...productDetails.udfValueMap},

                    productOptions: [...productDetails.productOptions],
                    variants: { ...productDetails.productVariants },
                }

                return {
                    ...INITIAL_STATE,
                    alertDialog: { ...INITIAL_ALERT_STATE },
                    // refreshFlag:false, // Refresh is done so set it to false
                    componentDidMount: "Y",
                    isPageLoading: false,
                    masterData: masterData,
                    ...projectDetailsState
                }
            } else {
                return {
                    ...INITIAL_STATE,
                    alertDialog: { ...INITIAL_ALERT_STATE },
                    // refreshFlag:false, // Refresh is done so set it to false
                    componentDidMount: "Y",
                    isPageLoading: false,
                    masterData: masterData
                }
            }

        }
        case "SET_MASTER_RESPONSE": {
            return {
                ...INITIAL_STATE,
                alertDialog: { ...INITIAL_ALERT_STATE },
                // refreshFlag:false, // Refresh is done so set it to false
                componentDidMount: "Y",
                isPageLoading: false,
                masterData: action.masterData,
            }
        }

        //OPTIONS CASES
        case "RESET_CREATE_OPTIONS_DIALOG": {
            return {
                ...state,
                addOptionsDialog: { ...INITIAL_OPTIONS_DIALOG_STATE },
                // refreshFlag:action.refreshFlag?true:false
            }
        }
        case "SHOW_CREATE_OPTIONS_DIALOG": {
            return {
                ...state,
                addOptionsDialog: {
                    ...INITIAL_OPTIONS_DIALOG_STATE,
                    showAddEditDialog: true,
                    optionsIndex: state.productOptions.length
                }
            }
        }
        case "SUBMIT_CREATE_OPTIONS_DIALOG": {
            //When options along with values are created for the first time
            const { finalOptionsObj, optionsIndex } = action;

            //lets deep copy product options
            let productOptions = JSON.parse(JSON.stringify(state.productOptions));
            productOptions.splice(optionsIndex, 1, { ...finalOptionsObj }); //Add New Option

            let variants = createVariants([...finalOptionsObj.optionValues], optionsIndex, productOptions);
            return {
                ...state,
                addOptionsDialog: { ...INITIAL_OPTIONS_DIALOG_STATE },
                productOptions: productOptions,
                variants: { ...variants }
            }
        }
        case "DELETE_OPTION": {
            const { optionsIndex } = action;
            let productOptions = JSON.parse(JSON.stringify(state.productOptions));
            productOptions.splice(optionsIndex, 1);

            let variants = createVariants([...productOptions[0].optionValues], 0);

            return {
                ...state,
                productOptions: productOptions,
                variants: { ...variants },
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "DELETE_OPTION_VALUE": {
            const { optionsIndex, optionValuesIdx } = action;

            //Deep Copy ProductOptions
            let productOptions = JSON.parse(JSON.stringify(state.productOptions));
            let optionValues = [...productOptions[optionsIndex].optionValues];
            let deletedFeIndex = optionValues[optionValuesIdx].feIndex;

            //delete the selected option value
            optionValues.splice(optionValuesIdx, 1);
            productOptions[optionsIndex].optionValues = [...optionValues];

            let variants = {};

            if (optionsIndex === 0) {
                productOptions[0].optionValues.forEach(option => {
                    if (option.feIndex !== deletedFeIndex) {
                        variants = {
                            ...variants,
                            [option.feIndex]: { ...state.variants[option.feIndex] }
                        }
                    }
                });
            } else if (optionsIndex === 1) {
                let firstOptionValues = [...productOptions[0].optionValues];
                let secondOptionValues = [...productOptions[1].optionValues];

                firstOptionValues.forEach(option1 => {
                    variants = {
                        ...variants,
                        [option1.feIndex]: {}
                    }
                    secondOptionValues.forEach((option2) => {

                        if (option2.feIndex !== deletedFeIndex) {
                            variants = {
                                ...variants,
                                [option1.feIndex]: {
                                    ...variants[option1.feIndex],
                                    [option2.feIndex]: {
                                        ...state.variants[option1.feIndex][option2.feIndex]
                                    }
                                }
                            }
                        }
                    })
                })
            }

            return {
                ...state,
                productOptions: productOptions,
                variants: variants,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "RESET_ADD_EDIT_OPTION_VALUE_DIALOG": {
            return {
                ...state,
                addEditOptionValueDialog: { ...INITIAL_EDIT_OPTION_VALUE_DIALOG_STATE },
            }
        }
        case "SHOW_ADD_EDIT_OPTION_VALUE_DIALOG": {
            const { optionsIndex, optionValuesIdx, isEdit } = action;

            return {
                ...state,
                addEditOptionValueDialog: {
                    ...INITIAL_EDIT_OPTION_VALUE_DIALOG_STATE,
                    showAddEditOptionValue: true,
                    isEdit: isEdit,
                    optionsIndex: optionsIndex,
                    optionValuesIdx: isEdit ? optionValuesIdx : "",
                    productOptionValue: isEdit
                        ? state.productOptions[optionsIndex].optionValues[optionValuesIdx].productOptionValue
                        : ""
                },
            }
        }
        case "SUBMIT_ADD_EDIT_OPTION_VALUE_DIALOG": {
            const { optionsIndex, productOptionValue, optionValuesIdx } = action;
            let productOptions = JSON.parse(JSON.stringify(state.productOptions));

            let variants = {};
            if (state.addEditOptionValueDialog?.isEdit) {
                productOptions[optionsIndex].optionValues[optionValuesIdx].productOptionValue = productOptionValue;
                variants = { ...state.variants };
            } else {
                let feIndexLastInsert = productOptions[optionsIndex].optionValuesLastIdx;
                productOptions[optionsIndex].optionValues.push({
                    productOptionValueId: "",
                    productOptionValue: productOptionValue,
                    feIndex: feIndexLastInsert,
                });
                //increment last index
                productOptions[optionsIndex].optionValuesLastIdx = feIndexLastInsert + 1

                if (optionsIndex === 0) {
                    //check if there 2 product options
                    if (productOptions.length === 2) {
                        let op2NewValues = {};
                        productOptions[1].optionValues.forEach(option => {
                            op2NewValues =  {
                                ...op2NewValues,
                                [option.feIndex]: { ...productVariantFields }
                            }
                        });

                        variants = {
                            ...state.variants,
                            [feIndexLastInsert]: {
                                ...op2NewValues
                            }
                        }
                    } else {
                        variants = {
                            ...state.variants,
                            [feIndexLastInsert]: {
                                ...productVariantFields
                            }
                        }
                    }
                } else if (optionsIndex === 1) {
                    //then there are 2 product options
                    productOptions[0].optionValues.forEach((option) => {
                        variants = {
                            ...variants,
                            [option.feIndex]: {
                                ...state.variants[option.feIndex],
                                [feIndexLastInsert]: { ...productVariantFields }
                            }
                        }
                    })
                }
            }

            return {
                ...state,
                productOptions: productOptions,
                variants: variants,
                addEditOptionValueDialog: {
                    ...INITIAL_EDIT_OPTION_VALUE_DIALOG_STATE,
                },
            }
        }
        case "RESET_EDIT_OPTION_NAME_DIALOG": {
            return {
                ...state,
                editOptionNameDialog: { ...INITIAL_EDIT_OPTION_NAME_DIALOG_STATE },
            }
        }
        case "SHOW_EDIT_OPTION_NAME_DIALOG": {
            const { optionsIndex } = action;
            return {
                ...state,
                editOptionNameDialog: {
                    ...INITIAL_EDIT_OPTION_NAME_DIALOG_STATE,
                    showEditOptionName: true,
                    productOptionName: state.productOptions[optionsIndex].productOptionName,
                    optionsIndex: optionsIndex,
                },
            }
        }
        case "SUBMIT_EDIT_OPTION_NAME_DIALOG": {
            const { optionsIndex, productOptionName } = action;
            let productOptions = JSON.parse(JSON.stringify(state.productOptions));
            productOptions[optionsIndex].productOptionName = productOptionName;
            return {
                ...state,
                productOptions: productOptions,
                editOptionNameDialog: {
                    ...INITIAL_EDIT_OPTION_NAME_DIALOG_STATE,
                },
            }
        }

        //VARIANT CASES
        case "RESET_EDIT_VARIANT_DIALOG": {
            return {
                ...state,
                editVariantPropertiesDialog: { ...INITIAL_EDIT_VARIANT_DIALOG_STATE },
            }
        }
        case "SHOW_EDIT_VARIANT_DIALOG": {
            const { op1FeIdx, op2FeIdx } = action;
            let variant = {};
            if (state.productOptions.length === 1) {
                variant = state.variants[op1FeIdx];
            } else if (state.productOptions.length === 2) {
                variant = state.variants[op1FeIdx][op2FeIdx];
            }

            return {
                ...state,
                editVariantPropertiesDialog: {
                    ...INITIAL_EDIT_VARIANT_DIALOG_STATE,
                    showEditVariantProperties: true,
                    op1FeIdx: op1FeIdx,
                    op2FeIdx: op2FeIdx,
                    variant: { ...variant }
                },
            }
        }
        case "SUBMIT_EDIT_VARIANT_DIALOG": {
            const { variant } = action;
            const { op1FeIdx, op2FeIdx } = state.editVariantPropertiesDialog;

            let variants = {}
            if (state.productOptions.length === 1) {
                variants = {
                    ...state.variants,
                    [op1FeIdx]: { ...variant }
                }
            } else if (state.productOptions.length === 2) {
                variants = {
                    ...state.variants,
                    [op1FeIdx]: {
                        ...state.variants[op1FeIdx],
                        [op2FeIdx]: { ...variant }
                    }
                }
            }
            return {
                ...state,
                variants: variants,
                editVariantPropertiesDialog: {
                    ...INITIAL_EDIT_VARIANT_DIALOG_STATE,
                },
            }
        }

        //IMAGE LIB CASES
        case "SHOW_IMAGE_LIBRARY": {
            const { sourceType } = action;
            return {
                ...state,
                showImageLibraryDialog: true,
                sourceType: sourceType,
                isMultiSelect: sourceType === 'media'
            }
        }
        case "HIDE_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: false,
                sourceType: "",
                isMultiSelect: false
            }
        }
        case "ONCLICK_SUBMIT_IMAGE_LIBRARY": {
            const { selectedImages } = action;
            if (state.isMultiSelect) {
                return {
                    ...state,
                    selectedMediaImages: [...state.selectedMediaImages, ...selectedImages],
                    showImageLibraryDialog: false,
                    sourceType: "",
                    isMultiSelect: false,
                }
            } else {
                return {
                    ...state,
                    primaryImageAttachmentObj: selectedImages[0],
                    showImageLibraryDialog: false,
                    sourceType: "",
                    isMultiSelect: false,
                }
            }
        }
        case "DELETE_PRIMARY_IMAGE": {
            return {
                ...state,
                primaryImageAttachmentObj: {}
            }
        }
        case "DELETE_IMAGE": {
            const { imageId } = action;
            let selectedMediaImages = state.selectedMediaImages.filter(item => item.productAttachmentId !== imageId)
            return {
                ...state,
                selectedMediaImages: [...selectedMediaImages],
            }
        }

        //OTHER CASES
        case "SET_API_ERR": {
            return {
                ...state,
                apiErr: action.apiErr
            }
        }
        case "INPUT_CHANGE": {
            const { name, value } = action;
            return {
                ...state,
                [name]: value
            }
        }
        case "SET_ERRORS": {
            return {
                ...state,
                errors: { ...action.errors },
            }
        }
        case "SELECT_VENDOR": {
            let vendor = { ...action.selectedOption }
            if(action.selectedOption.__isNew__){
                vendor = {
                    vendorId:"",
                    vendorName: action.selectedOption.label
                }
            }
            return {
                ...state,
                selectedVendor: vendor
            }
        }
        case "SELECT_PRODUCT_TYPE": {
            let type = { ...action.selectedOption }
            if(action.selectedOption.__isNew__){
                type = {
                    productTypeId:"",
                    productTypeName: action.selectedOption.label
                }
            }
            return {
                ...state,
                selectedProductType: type
            }
        }
        case "SELECT_PRODUCT_GROUPS": {
            return {
                ...state,
                selectedProductGroups: [...action.selectedOption]
            }
        }
        case "SELECT_PRODUCT_TAGS": {
            return {
                ...state,
                selectedProductTags: action.selectedOption.map(option => {
                    if (option.__isNew__) {
                        return {
                            productTagId: "",
                            productTagName: option.label
                        }
                    }
                    return option;
                })
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "SET_UDF_VALUE": {
            const {name, value} = action;
            return {
                ...state,
                udfValueMap: {
                    ...state.udfValueMap,
                    [name]: value
                }
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};