import { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { CREATE_CUSTOMER_PAGE, CUSTOMER_PAGE_DETAILS, GET_API_MODULES, UPDATE_CUSTOMER_PAGE } from '../../utils/apiEndPoints';
import { ALLOWED_FILE_SIZE_IN_MB, WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';
import {
    ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper,
    BackButton
} from '../../components/styles/listingHeaderStyles';
import { FormWrapper, FieldGroupWrapper, ButtonSecondary, JsonBtns } from '../../components/styles/addEditFormStyles';
import { FaArrowLeftLong } from "react-icons/fa6";
import CreateUpdatePageField from './createUpdatePageField';
import { GetReducer } from './reducerCreateUpdateCustomerPage';
import PageFields from './pageFields';
import ImageLibrary from '../../components/imageLibraryDialog';
import AlertDialog from '../../components/dialogs/alertDialog';
import { TfiUpload } from 'react-icons/tfi';
import { DragDropContext} from "react-beautiful-dnd";

const CreateUpdateCustomerPage = (props) => {
    const { addEditCustomerPageComp, onClickSubmit, onClickCancel } = props;
    const [componentState, dispatch] = GetReducer();
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditCustomerPageComp.showAddEditCustomerPageComp) {
            initCompState();
        }
    }, [addEditCustomerPageComp.showAddEditCustomerPageComp]);

    const initCompState = async () => {
        if (addEditCustomerPageComp.isEdit) {
            getPageDetails();
        } else {
            dispatch({
                type: "RESET_STATE"
            })
        }
        inputRef.current.focus();
    }

    const getPageDetails = async () => {
        try {
            let response = await callAuthApi(CUSTOMER_PAGE_DETAILS, {
                pageId: addEditCustomerPageComp.pageObj.pageId
            }, user, logout);

            dispatch({
                type: "SET_PAGE_DETAILS",
                pageDetails: response.data
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_API_ERR",
                errMsg: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            })
        }
    }

    //Add Edit Page Field
    const showAddPageFieldDialog = () => {
        dispatch({
            type: "SHOW_CREATE_PAGE_FIELD_DIALOG"
        })
    }

    const onClickAddEditPageFieldCancel = () => {
        dispatch({
            type: "RESET_PAGE_FIELD_DIALOG"
        })
    }

    const onClickAddEditPageFieldSubmit = (item) => {
        if (componentState.addEditPageFieldDialog.isFieldEdit) {
            dispatch({
                type: "EDIT_FIELD_ITEM",
                item: item
            })
        } else {
            dispatch({
                type: "ADD_FIELD_ITEM",
                item: item
            })
        }
    }

    const showEditPageFieldDialog = (item, fieldIdx) => {
        dispatch({
            type: "SET_PAGE_FIELD_DIALOG",
            addEditPageFieldDialog: {
                showaddEditPageFieldDialog: true,
                isFieldEdit: true,
                fieldIdx: fieldIdx,
                pageFieldObj: {
                    pageContentId: item.pageContentId,
                    pageFieldName: item.pageFieldName,
                    pageFieldType: item.pageFieldType,
                }
            }
        })
    }

    //ALERT Dialog
    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, fieldIdx) => {
        switch (operation) {
            case "Delete":
                dispatch({
                    type: "DELETE_FIELD",
                    fieldIdx: fieldIdx
                })
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showDeleteFieldAlert = (item, fieldIdx) => {
        dispatch({
            type: "SET_ALERT",
            alertState: {
                showAlertDialog: true,
                heading: "Delete Field",
                message: "Are you sure you want to delete " + item.pageFieldName + "?",
                type: "ALERT",
                cancelBtn: true,
                okayBtn: true,
                cancelBtnLabel: "Cancel",
                okayBtnLabel: "Delete",
                operation: "Delete",
                selectedItem: fieldIdx,
            }
        })
    }

    //Image Library Dialog Functions
    const showImageLibrary = (pageImageFieldIdx) => {
        dispatch({
            type:"SHOW_IMAGE_LIBRARY",
            pageImageFieldIdx:pageImageFieldIdx
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        dispatch({
            type:"HIDE_IMAGE_LIBRARY"
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            const {productAttachmentPath, productAttachmentId} = selectedImages[0];
            dispatch({
                type: "IMAGE_LIBRARY_SUBMIT",
                productAttachmentPath: productAttachmentPath,
                productAttachmentId: productAttachmentId,
            })
        }
    }

    //Util Functions
    const setFieldValue = (value, fieldIdx) => {
        dispatch({
            type: "SET_FIELD_VALUE",
            fieldIdx: fieldIdx,
            value: value
        })
    }

    const clearImageField = (pageImageFieldIdx) => {
        dispatch({
            type:"CLEAR_IMAGE_FIELD",
            pageImageFieldIdx:pageImageFieldIdx
        })
    }

    const inputChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: e.target.value
        })
    }

    const handleUrlHandle = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, '-');
        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue.toLowerCase()
        })
    }

    const downloadFieldsJson = () => {
        const json = JSON.stringify(componentState.fields);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.download = componentState.pageName + ".json";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    const fileValidation = (filePath) => {
        var allowedExtensions = /(\.json)$/i;

        if (!allowedExtensions.exec(filePath)) {
            return false;
        } else {
            return true;
        }
    }

    const changeImage = (e) => {
        e.preventDefault();
        let uploadObj = {};

        let hasErrors = false;
        let errorMessage = "";

        if (!isEmptyVariable(e.target.files)) {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                let obj = {
                    uploading: true,
                    error: "",
                }

                if (!fileValidation(file.name)) {
                    errorMessage = "Error: Only json is allowed";
                    hasErrors = true;
                    break;
                }

                if (sizeInMB > ALLOWED_FILE_SIZE_IN_MB) {
                    errorMessage = "Error: Please upload file having size less than " + ALLOWED_FILE_SIZE_IN_MB + " Mb";
                    hasErrors = true;
                    break;
                }
            }

            if (hasErrors) {
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Error",
                        message: errorMessage,
                        type: "ALERT",
                        cancelBtn: false,
                        okayBtn: true,
                        okayBtnLabel: "Okay",
                    }
                });
            } else {

                const fileReader = new FileReader();

                fileReader.readAsText(e.target.files[0], "UTF-8");
                fileReader.onload = e => {
                    console.log("e.target.result", typeof(e.target.result));
                    dispatch({
                        type:"SET_FIELDS_FROM_JSON",
                        fieldsStr: e.target.result
                    })
                };
            }
        }
    }

    const onFieldDragEnd = (result) => {
        const { destination, source } = result;
        //Dropped outside
        if (!destination) {
            return;
        }

        //Dropped to same position
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        dispatch({
            type:"MOVE_FIELD",
            destinationIndex:destination.index,
            sourceIndex:source.index
        })
    }

    //API and Validations
    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.pageName)) {
            hasErrors = true;
            errors.pageName = "Please enter the Page name"
        }

        if (isEmptyArray(componentState.fields)) {
            hasErrors = true;
            errors.fieldsErr = "Please enter atleast one field"
        }

        if (isEmptyVariable(componentState.seoTitle)) {
            hasErrors = true;
            errors.seoTitle = "Please enter the SEO title"
        }

        if (isEmptyVariable(componentState.seoDescription)) {
            hasErrors = true;
            errors.seoDescription = "Please enter the SEO description"
        }

        if (isEmptyVariable(componentState.urlHandle)) {
            hasErrors = true;
            errors.urlHandle = "Please enter the URL handle"
        }

        dispatch({
            type: "SET_ERRORS",
            errors:errors
        })

        return hasErrors;
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditCustomerPageComp.isEdit) {
                    await updateCustomerPage();
                } else {
                    await createCustomerPage();
                }
                //onClickSubmit(true);
            } catch (alertErr) {
                dispatch({
                    type: "SET_API_ERR",
                    errMsg: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })
            }
        } else {
            // setTimeout(() => {
            //     setComponentState({
            //         ...componentState,
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Call
    const createCustomerPage = async () => {
        await callAuthApi(CREATE_CUSTOMER_PAGE, {
            pageName: componentState.pageName,
            pageValues: JSON.stringify(componentState.fields),
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
        }, user, logout)
    }

    //API Call
    const updateCustomerPage = async () => {
        await callAuthApi(UPDATE_CUSTOMER_PAGE, {
            pageId: addEditCustomerPageComp.pageObj.pageId,
            pageName: componentState.pageName,
            pageValues: JSON.stringify(componentState.fields),
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
        }, user, logout)
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{"Add Customer Page"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={12}>
                    <FieldGroupWrapper>
                        <label>Page Name*</label>
                        <input
                            value={componentState.pageName}
                            name="pageName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.pageName) &&
                            <span className="err-span m-b-10">{componentState.errors.pageName}</span>
                        }
                    </FieldGroupWrapper>
                    <FieldGroupWrapper>
                        <JsonBtns>
                            <h6>Fields</h6>
                            <ButtonSecondary onClick={downloadFieldsJson}>Download JSON</ButtonSecondary>
                            <div className="upload-json">
                                <input
                                    name="file"
                                    type="file"
                                    id="uploadJson"
                                    onChange={changeImage}
                                />
                                <label for="uploadJson">
                                    <p>Upload JSON</p>
                                </label>
                            </div>
                        </JsonBtns>
                        <DragDropContext onDragEnd={onFieldDragEnd} >
                            <PageFields
                                fields={componentState.fields}
                                clearImageField={clearImageField}
                                showDeleteFieldAlert={showDeleteFieldAlert}
                                showEditPageFieldDialog={showEditPageFieldDialog}
                                setFieldValue={setFieldValue}
                                showImageLibrary={showImageLibrary}
                            />
                        </DragDropContext>
                        <ButtonSecondary onClick={showAddPageFieldDialog}>Add Field</ButtonSecondary>

                        {
                            !isEmptyVariable(componentState.errors?.fieldsErr) &&
                            <span className="err-span m-b-10">{componentState.errors.fieldsErr}</span>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Search Engine</h6>
                        <label>SEO Title</label>
                        <input
                            value={componentState.seoTitle}
                            name="seoTitle"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5' 
                            style = {{color:componentState.seoTitle.length>60?"red":"#555555"}}>
                            {componentState.seoTitle.length+" of 60 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.seoTitle}</span>
                        }
                        
                        <label>SEO Description</label>
                        <textarea
                            value={componentState.seoDescription}
                            name="seoDescription"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span  className='help-span m-b-20 m-t-5'
                            style = {{color:componentState.seoDescription.length>320?"red":"#555555"}}>
                            {componentState.seoDescription.length+" of 320 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoDescription) &&
                            <span className="err-span m-b-10">{componentState.errors.seoDescription}</span>
                        }

                        <label>URL Handle</label>
                        <input
                            value={componentState.urlHandle}
                            name="urlHandle"
                            onChange={handleUrlHandle}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.urlHandle) &&
                            <span className="err-span m-b-10">{componentState.errors.urlHandle}</span>
                        }

                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <CreateUpdatePageField
            addEditPageFieldDialog={{ ...componentState.addEditPageFieldDialog }}
            onClickSubmit={onClickAddEditPageFieldSubmit}
            onClickCancel={onClickAddEditPageFieldCancel}
        />

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />

        <AlertDialog
            alertState={{ ...componentState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default CreateUpdateCustomerPage;
