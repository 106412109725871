import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';

export const ForgotPassSection = styled.section`
    background: ${Colors.loginBackground};
    min-height: 100vh;
`;

export const ForgotPassWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh - 90px);
`;

export const ForgotPassCol = styled.div`
    max-width:500px;
    min-width:450px;
    margin:auto;
    text-align:center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 25px;
    padding:40px;
    background-color: ${Colors.whiteColor};

    p{
        font-size:14px;
        opacity:.85;
        margin-bottom: 30px;
    }

    input{
        width:100%;
        margin-bottom:15px;
        padding:10px 15px;
        border-radius:0;
        border-radius: 4px;
    }

    button{
        border:none;
        width:100%;
        padding:10px 15px;
        text-align:center;
        justify-content: center;
        color:#fff;
        text-transform:uppercase;
        margin-bottom:15px;
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;

        
    }
    a{
        &:hover{
            text-decoration: underline;
        }
    }
    .err-span{
        margin-bottom:8px;
    }
`;

export const LogoCol = styled.div`
    text-align: center;
    margin-bottom: 10px;
    img{
        max-width: 150px;
    }
`;