import { FaRegTrashCan } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCopyAlt, BiEditAlt } from "react-icons/bi";

export const WARNING_MSG_TIMEOUT = 5000;
export const DEFAULT_RESULT_SIZE = 10;
export const ALLOWED_FILE_SIZE_IN_MB = 5;
export const CATEGORY_OTHERS_ID = 100;
export const NO_RECORDS_WARNING = "No records found";
export const EMPTY_SEARCH_WARNING = "There aren't any results for that query.";
export const FORM_VALIDATION_MESSAGE = "One or more fields have an error. Please check and try again";

export const DISCOUNT_PRODUCTS = "Product Discount";
export const DISCOUNT_VALUE_PERCENTAGE = "Percentage";
export const DISCOUNT_VALUE_FIXED_AMOUNT = "Fixed Amount";
export const DISCOUNT_ORDER = "Order Discount";

export const RESULT_SIZE_ARRAY = [
    {
        label: "10",
        isSelected: true
    },
    {
        label: "20",
        isSelected: false
    },
    {
        label: "50",
        isSelected: false
    },
];

export const DEFAULT_RESULT_SIZE_IMAGE = 50;
export const RESULT_SIZE_ARRAY_IMAGE = [
    {
        label: "20",
        isSelected: false
    },
    {
        label: "50",
        isSelected: true
    },
    {
        label: "100",
        isSelected: false
    },
];

export const INITIAL_ALERT_STATE = {
    showAlertDialog: false,
    heading: "",
    message: "",
    type: "ALERT",
    cancelBtn: false,
    cancelBtnLabel: "Cancel",
    okayBtn: false,
    okayBtnLabel: "Okay",
    operation: "",
    selectedItem: {},

}

export const EDIT_ACTION = {
    label: "Edit",
    icon: <BiEditAlt />
}
export const SHOW_USERS_ACTION = {
    label: "Users",
    icon: <BiEditAlt />
}
export const RENAME_ACTION = {
    label: "Rename",
    icon: <MdModeEdit />
}
export const APPLY_ACTION = {
    label: "Apply",
    icon: <IoCheckmarkCircleOutline />
}
export const DELETE_ACTION = {
    label: "Delete",
    icon: <FaRegTrashCan />
}
export const DUPLICATE_ACTION = {
    label: "Duplicate",
    icon: <BiCopyAlt />
}
export const DEACTIVATE_ACTION = {
    label: "Deactivate",
    icon: <BiCopyAlt />
}
export const SUSPEND_ACTION = {
    label: "Suspend",
    icon: <BiCopyAlt />
}
export const ACTIVATE_ACTION = {
    label: "Activate",
    icon: <BiCopyAlt />
}
export const APPROVE_ACTION = {
    label: "Approve",
    icon: <BiCopyAlt />
}
export const UPDATE_AVAILABLE = {
    label: "Update Available",
    icon: <BiCopyAlt />
}
export const UPDATE_UNAVAILABLE = {
    label: "Update Unavailable",
    icon: <BiCopyAlt />
}
export const INVENTORY_LOG = {
    label: "Inventory Log",
    icon: <BiCopyAlt />
}