import { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import styled from 'styled-components';
import CustomerListDialog from '../../components/customerListDialog/customerListDialog';
import AlertDialog from '../../components/dialogs/alertDialog';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import DatetimePickerComp from '../../components/input/datetimePicker';
import RadioButton from '../../components/input/radioButton';
import ProductGroupListDialog from '../../components/productGroupListDialog/productGroupListDialog';
import ProductListDialog from '../../components/productListDialog/productListDialog';
import { ButtonSecondary, FieldGroupWrapper, FormWrapper } from '../../components/styles/addEditFormStyles';
import {
    BackButton,
    ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from '../../hooks/useAuth';
import { CREATE_DISCOUNT, DISCOUNT_DETAILS, UPDATE_DISCOUNT } from '../../utils/apiEndPoints';
import { DISCOUNT_PRODUCTS } from '../../utils/constants';
import { callAuthApi, convertDateTimeObjToString, ifEmptyReturnStr, isEmptyObject, isEmptyVariable } from '../../utils/utilFunctions';
import { GetReducer } from './reducerCreateUpdateDiscount';

const CreateUpdateDiscount = (props) => {
    const { addEditDiscountComp, onClickSubmit, onClickCancel } = props;
    const [componentState, dispatch] = GetReducer();
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditDiscountComp.showAddEditDiscountComp) {
            initCompState();
        }
    }, [addEditDiscountComp.showAddEditDiscountComp]);

    const initCompState = async () => {
        if (addEditDiscountComp.isEdit) {
            getDiscountDetails();
        } else {
            dispatch({
                type: "RESET_STATE"
            })
        }
        inputRef.current.focus();
    }

    const getDiscountDetails = async () => {
        try {
            let response = await callAuthApi(DISCOUNT_DETAILS, {
                discountId: addEditDiscountComp.discountObj.discountId
            }, user, logout);

            dispatch({
                type: "SET_DISCOUNT_DETAILS",
                discountDetails: response.data.result
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_API_ERR",
                errMsg: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            })
        }
    }

    //ALERT Dialog
    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, fieldIdx) => {
        switch (operation) {
            case "Delete":
                dispatch({
                    type: "DELETE_FIELD",
                    fieldIdx: fieldIdx
                })
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const onClickAddEditProductsSubmit = (discountProducts) => {
        dispatch({
            type: "SUBMIT_PRODUCT_LIST_DIALOG",
            discountProducts: discountProducts
        })
    }

    const onClickAddEditProductsCancel = () => {
        dispatch({ type: "HIDE_PRODUCT_LIST_DIALOG" })
    }

    const onClickAddEditProductGroupsSubmit = (discountProductGroups) => {
        dispatch({
            type: "SUBMIT_PRODUCT_GROUP_LIST_DIALOG",
            discountProductGroups: discountProductGroups
        })
    }

    const onClickAddEditProductGroupsCancel = () => {
        dispatch({ type: "HIDE_PRODUCT_GROUP_LIST_DIALOG" })
    }

    const onClickAddEditCustomersSubmit = (discountCustomers) => {
        dispatch({
            type: "SUBMIT_CUSTOMER_LIST_DIALOG",
            discountCustomers: discountCustomers
        })
    }

    const onClickAddEditCustomersCancel = () => {
        dispatch({ type: "HIDE_CUSTOMER_LIST_DIALOG" })
    }

    //INPUT and DROPDOWN Change    
    const inputChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: e.target.value
        })
    }

    const isCouponHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "isCoupon",
            value: e.target.name
        })
    }

    const minReqHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "minimumRequirements",
            value: e.target.name
        })
    }

    const allCustomersHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "allCustomers",
            value: e.target.name
        })
    }

    const discountValueTypeDropdownItemClick = (item) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "discountValueType",
            value: item.discountValueType
        })
    }

    const discountToDropdownItemClick = (item) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "discountTo",
            value: item.discountTo
        })
    }

    const usageLimitDropdownItemClick = (item) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "usageLimit",
            value: item.usageLimit
        })
    }

    const handleDecimalInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9.]/g, '');

        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue
        })
    };

    const handleIntegerInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9]/g, '');

        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue
        })
    };

    const onChangeStartDateTime = (value) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "startDateTime",
            value: value
        })
    }

    const onChangeEndDateTime = (value) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "endDateTime",
            value: value
        })
    }

    //API and Validations
    const validateForm = () => {
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(componentState.discountTitle)) {
            hasErrors = true;
            errors.discountTitle = "Please enter the Page name"
        }

        // if (isEmptyVariable(componentState.isCoupon)) {
        //     hasErrors = true;
        //     errors.isCoupon = "Please select discount type"
        // }

        if (!isEmptyVariable(componentState.isCoupon) && isEmptyVariable(componentState.couponCode)) {
            hasErrors = true;
            errors.couponCode = "Please enter the coupon code"
        }

        // if (isEmptyVariable(componentState.discountValueType)) {
        //     hasErrors = true;
        //     errors.discountValueType = "Please select discount value type"
        // }

        if (componentState.discountValueType === 'Percentage' &&
            isEmptyVariable(componentState.discountPercentage)) {
            hasErrors = true;
            errors.discountPercentage = "Please enter discount percentage"
        }

        if (componentState.discountValueType === 'Fixed Amount' &&
            isEmptyVariable(componentState.discountFixedAmount)) {
            hasErrors = true;
            errors.discountFixedAmount = "Please enter discount amount"
        }

        // if (isEmptyVariable(componentState.minimumRequirements)) {
        //     hasErrors = true;
        //     errors.discountPercentage = "Please select minimum requirements"
        // }

        if (componentState.minimumRequirements === 'Min Order Amount' &&
            isEmptyVariable(componentState.minimumPurchaseAmount)) {
            hasErrors = true;
            errors.minimumPurchaseAmount = "Please enter minimum order amount"
        }

        if (componentState.usageLimit === "Custom" && isEmptyVariable(componentState.usageCount)) {
            hasErrors = true;
            errors.usageCount = "Please enter the uses per customer"
        }

        if (componentState.startDateTime === "Custom") {
            hasErrors = true;
            errors.startDateTime = "Please select the start date"
        }

        if (componentState.discountTo === "Specific Products" && isEmptyObject(componentState.discountProducts)) {
            hasErrors = true;
            errors.discountProducts = "Please select the products"
        }

        if (componentState.discountTo === "Specific Collections" && isEmptyObject(componentState.discountProductGroups)) {
            hasErrors = true;
            errors.discountProductGroups = "Please select the collections"
        }

        if (componentState.allCustomers === "Specific" && isEmptyObject(componentState.discountCustomers)) {
            hasErrors = true;
            errors.discountCustomers = "Please select the customers"
        }

        dispatch({
            type: "SET_ERRORS",
            errors: errors
        })

        return hasErrors;
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditDiscountComp.isEdit) {
                    await updateDiscount();
                } else {
                    await createDiscount();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                dispatch({
                    type: "SET_API_ERR",
                    errMsg: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })
            }
        } else {
            // setTimeout(() => {
            //     setComponentState({
            //         ...componentState,
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Call
    const createDiscount = async () => {
        await callAuthApi(CREATE_DISCOUNT, {
            discountTitle: componentState.discountTitle,
            discountType: addEditDiscountComp.discountType,
            isCoupon: componentState.isCoupon,
            couponCode: componentState.couponCode,
            discountValueType: addEditDiscountComp.discountValueType,
            discountPercentage: componentState.discountPercentage,
            discountFixedAmount: componentState.discountFixedAmount,
            discountTo: componentState.discountTo,
            minimumRequirements: componentState.minimumRequirements,
            minimumPurchaseAmount: componentState.minimumPurchaseAmount,
            startDateTime: convertDateTimeObjToString(componentState.startDateTime),
            endDateTime: isEmptyVariable(componentState.endDateTime) ? null : convertDateTimeObjToString(componentState.endDateTime),
            allCustomers: componentState.allCustomers === "All" ? "Y" : "N",
            usageCount: componentState.usageCount,

            products: JSON.stringify(Object.keys(componentState.discountProducts)),
            collections: JSON.stringify(Object.keys(componentState.discountProductGroups)),
            customers: JSON.stringify(Object.keys(componentState.discountCustomers))
        }, user, logout)
    }

    //API Call
    const updateDiscount = async () => {
        await callAuthApi(UPDATE_DISCOUNT, {
            discountId: addEditDiscountComp.discountObj.discountId,
            discountTitle: componentState.discountTitle,
            isCoupon: componentState.isCoupon,
            couponCode: componentState.couponCode,
            discountValueType: addEditDiscountComp.discountValueType,
            discountPercentage: componentState.discountPercentage,
            discountFixedAmount: componentState.discountFixedAmount,
            discountTo: componentState.discountTo,
            minimumRequirements: componentState.minimumRequirements,
            minimumPurchaseAmount: componentState.minimumPurchaseAmount,
            startDateTime: convertDateTimeObjToString(componentState.startDateTime),
            endDateTime: isEmptyVariable(componentState.endDateTime) ? null : convertDateTimeObjToString(componentState.endDateTime),
            allCustomers: componentState.allCustomers === "All" ? "Y" : "N",
            usageCount: componentState.usageCount,


            products: JSON.stringify(Object.keys(componentState.discountProducts)),
            collections: JSON.stringify(Object.keys(componentState.discountProductGroups)),
            customers: JSON.stringify(Object.keys(componentState.discountCustomers))
        }, user, logout)
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>
                        {
                            addEditDiscountComp.isEdit
                                ?
                                "Edit " + addEditDiscountComp.discountType
                                :
                                "Add " + addEditDiscountComp.discountType
                        }
                    </h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={12}>
                    <FieldGroupWrapper>
                        <label>Discount Title*</label>
                        <input
                            value={componentState.discountTitle}
                            name="discountTitle"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.discountTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.discountTitle}</span>
                        }

                        <Row>
                            <Col md={6}>
                                <label className='m-b-10'>Discount Type*</label>
                                <Row className='m-b-10'>
                                    <Col md={4}>
                                        <RadioButton
                                            radioButtonId={"isCouponCode"}
                                            radioButtonLabel={"Coupon Code"}
                                            handleChange={isCouponHandleChange}
                                            name="Y"
                                            checked={componentState.isCoupon === "Y"}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <RadioButton
                                            radioButtonId={"automaticDiscount"}
                                            radioButtonLabel={"Automatic Discount"}
                                            handleChange={isCouponHandleChange}
                                            name="N"
                                            checked={componentState.isCoupon === "N"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            {
                                componentState.isCoupon === "Y" &&
                                <Col md={6}>
                                    <label>Coupon Code*</label>
                                    <input
                                        value={componentState.couponCode}
                                        name="couponCode"
                                        onChange={inputChange}
                                        className="m-b-5"
                                    />
                                    <span className="help-span">Customers are required to input this code during checkout.</span>
                                    {
                                        !isEmptyVariable(componentState.errors?.couponCode) &&
                                        <span className="err-span m-t-10 m-b-0">{componentState.errors.couponCode}</span>
                                    }
                                </Col>
                            }
                        </Row>
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Discount Details</h6>
                        <Row>
                            {/* <Col md={6}>
                                <label>Discount Value*</label>
                                <BasicDropdown
                                    placeholder={"Select Discount Value Type"}
                                    selectedValue={componentState.discountValueType}
                                    label="discountValueType"
                                    options={[{ discountValueType: "Percentage" }, { discountValueType: "Fixed Amount" }]}
                                    dropdownItemClick={discountValueTypeDropdownItemClick}
                                    wrapperStyles={{ marginBottom: 0 }}
                                />
                            </Col> */}
                            <Col md={6}>
                                {
                                    addEditDiscountComp.discountValueType === 'Percentage' &&
                                    <>
                                        <label>Discount Percentage*</label>
                                        <input
                                            placeholder={"0.00"}
                                            value={componentState.discountPercentage}
                                            name="discountPercentage"
                                            onChange={handleDecimalInputChange}
                                            className="m-b-20"
                                        />
                                        {
                                            !isEmptyVariable(componentState.errors?.discountPercentage) &&
                                            <span className="err-span m-t-0 m-b-0">{componentState.errors.discountPercentage}</span>
                                        }
                                    </>
                                }
                                {
                                    addEditDiscountComp.discountValueType === 'Fixed Amount' &&
                                    <>
                                        <label>Discount Amount*</label>
                                        <input
                                            placeholder={"0.00"}
                                            value={componentState.discountFixedAmount}
                                            name="discountFixedAmount"
                                            onChange={handleDecimalInputChange}
                                            className="m-b-20"
                                        />
                                        {
                                            !isEmptyVariable(componentState.errors?.discountFixedAmount) &&
                                            <span className="err-span m-t-10 m-b-0">{componentState.errors.discountFixedAmount}</span>
                                        }
                                    </>
                                }
                            </Col>

                            {
                                addEditDiscountComp.discountType === DISCOUNT_PRODUCTS &&
                                <>
                                    <Col md={6}>
                                        <label>Applicable to*</label>
                                        <BasicDropdown
                                            placeholder={"Select Applicable To"}
                                            selectedValue={componentState.discountTo}
                                            label="discountTo"
                                            options={[{ discountTo: "All Products" }, { discountTo: "Specific Collections" }, { discountTo: "Specific Products" }]}
                                            dropdownItemClick={discountToDropdownItemClick}
                                            wrapperStyles={{ marginBottom: 0 }}
                                            disabled={addEditDiscountComp.isEdit}
                                        />
                                    </Col>
                                    {
                                        componentState.discountTo === "Specific Products" &&
                                        <Col md="6">
                                            <label>&nbsp;</label>
                                            <FlexLayout>
                                                <ButtonSecondary onClick={() => { dispatch({ type: "DISPLAY_PRODUCT_LIST_DIALOG" }) }}>
                                                    Browse Products
                                                </ButtonSecondary>
                                                {
                                                    !isEmptyObject(componentState.discountProducts) &&
                                                    <p>
                                                        {
                                                            Object.keys(componentState.discountProducts).length === 1
                                                                ?
                                                                "1 Product Selected"
                                                                :
                                                                Object.keys(componentState.discountProducts).length + " Products Selected"
                                                        }
                                                    </p>
                                                }
                                            </FlexLayout>
                                            {
                                                !isEmptyVariable(componentState.errors?.discountProducts) &&
                                                <span className="err-span m-t-10 m-b-0">{componentState.errors.discountProducts}</span>
                                            }
                                        </Col>
                                    }
                                    {
                                        componentState.discountTo === "Specific Collections" &&
                                        <Col md="6">
                                            <label>&nbsp;</label>
                                            <FlexLayout>
                                                <ButtonSecondary onClick={() => { dispatch({ type: "DISPLAY_PRODUCT_GROUP_LIST_DIALOG" }) }}>
                                                    Browse Collections
                                                </ButtonSecondary>
                                                {
                                                    !isEmptyObject(componentState.discountProductGroups) &&
                                                    <p>
                                                        {
                                                            Object.keys(componentState.discountProductGroups).length === 1
                                                                ?
                                                                "1 Collection Selected"
                                                                :
                                                                Object.keys(componentState.discountProductGroups).length + " Collections Selected"
                                                        }
                                                    </p>
                                                }
                                            </FlexLayout>
                                            {
                                                !isEmptyVariable(componentState.errors?.discountProductGroups) &&
                                                <span className="err-span m-t-10 m-b-0">{componentState.errors.discountProductGroups}</span>
                                            }
                                        </Col>
                                    }

                                </>
                            }

                        </Row>
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Minimum Requirments</h6>
                        <Row className='m-b-10'>
                            <Col md={12} className='m-b-5'>
                                <RadioButton
                                    radioButtonId={"no-min-requirments"}
                                    radioButtonLabel={"No Minimum Requirements"}
                                    handleChange={minReqHandleChange}
                                    name="No"
                                    checked={componentState.minimumRequirements === "No"}
                                />
                            </Col>
                            <Col md={12} className='m-b-10'>
                                <RadioButton
                                    radioButtonId={"min-purchase-amount"}
                                    radioButtonLabel={"Minimum Order Amount"}
                                    handleChange={minReqHandleChange}
                                    name="Min Order Amount"
                                    checked={componentState.minimumRequirements === "Min Order Amount"}
                                />
                            </Col>
                            {
                                componentState.minimumRequirements === 'Min Order Amount' &&
                                <Col md={6}>
                                    <label>Minimum Order Amount*</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={componentState.minimumPurchaseAmount}
                                        name="minimumPurchaseAmount"
                                        onChange={handleDecimalInputChange}
                                        className="m-b-0"
                                    />
                                    {
                                        !isEmptyVariable(componentState.errors?.minimumPurchaseAmount) &&
                                        <span className="err-span m-t-10 m-b-0">{componentState.errors.minimumPurchaseAmount}</span>
                                    }
                                </Col>
                            }
                        </Row>
                    </FieldGroupWrapper>
                    {
                        componentState.isCoupon === "Y" &&
                        <FieldGroupWrapper>
                            <h6>Customers</h6>
                            <Col md={12} className='m-b-5'>
                                <RadioButton
                                    radioButtonId={"all-customers"}
                                    radioButtonLabel={"All Customers"}
                                    handleChange={allCustomersHandleChange}
                                    name="All"
                                    checked={componentState.allCustomers === "All"}
                                />
                            </Col>
                            <Col md={12} className='m-b-10'>
                                <Row>
                                    <Col md={6}>
                                        <RadioButton
                                            radioButtonId={"specific-customers"}
                                            radioButtonLabel={"Specific Customers"}
                                            handleChange={allCustomersHandleChange}
                                            name="Specific"
                                            checked={componentState.allCustomers === "Specific"}
                                        />
                                    </Col>
                                    {
                                        componentState.allCustomers === "Specific" &&
                                        <Col md={6}>
                                            <FlexLayout>
                                                <ButtonSecondary onClick={() => { dispatch({ type: "DISPLAY_CUSTOMER_LIST_DIALOG" }) }}>
                                                    Select Customers
                                                </ButtonSecondary>
                                                {
                                                    !isEmptyObject(componentState.discountCustomers) &&
                                                    <p>
                                                        {
                                                            Object.keys(componentState.discountCustomers).length === 1
                                                                ?
                                                                "1 Customer Selected"
                                                                :
                                                                Object.keys(componentState.discountCustomers).length + " Customers Selected"
                                                        }
                                                    </p>
                                                }
                                            </FlexLayout>
                                            {
                                                !isEmptyVariable(componentState.errors?.discountCustomers) &&
                                                <span className="err-span m-t-10 m-b-0">{componentState.errors.discountCustomers}</span>
                                            }
                                        </Col>
                                    }
                                </Row>
                            </Col>

                            <Col md={6}>
                                <label>Usage Limit*</label>
                                <BasicDropdown
                                    placeholder={"Select Usage Limit"}
                                    selectedValue={componentState.usageLimit}
                                    label="usageLimit"
                                    options={[{ usageLimit: "Only Once" }, { usageLimit: "Custom" }, { usageLimit: "Unlimited" }]}
                                    dropdownItemClick={usageLimitDropdownItemClick}
                                    wrapperStyles={{ marginBottom: 15 }}
                                />
                            </Col>
                            {
                                componentState.usageLimit === "Custom" &&
                                <Col md={6}>
                                    <label>Uses Per Customer*</label>
                                    <input
                                        placeholder={"0"}
                                        value={componentState.usageCount}
                                        name="usageCount"
                                        onChange={handleIntegerInputChange}
                                        className="m-b-0"
                                    />
                                    {
                                        !isEmptyVariable(componentState.errors?.usageCount) &&
                                        <span className="err-span m-t-10 m-b-0">{componentState.errors.usageCount}</span>
                                    }
                                </Col>
                            }

                        </FieldGroupWrapper>
                    }

                    <FieldGroupWrapper>
                        <h6>Discount Validity</h6>
                        <Row>
                            <Col md="6">
                                <label>Start Date*</label>
                                <DatetimePickerComp
                                    value={componentState.startDateTime}
                                    onChange={onChangeStartDateTime}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.startDateTime) &&
                                    <span className="err-span m-t-10 m-b-0">{componentState.errors.startDateTime}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>End Date</label>
                                <DatetimePickerComp
                                    value={componentState.endDateTime}
                                    onChange={onChangeEndDateTime}
                                />
                            </Col>
                        </Row>
                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>

        {/* <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        /> */}

        {
            componentState.showAddEditProductsflag &&
            <ProductListDialog
                showAddEditProductsflag={componentState.showAddEditProductsflag}
                onClickSubmit={onClickAddEditProductsSubmit}
                onClickCancel={onClickAddEditProductsCancel}
                discountProducts={componentState.discountProducts}
            />
        }

        {
            componentState.showAddEditProductGroupsflag &&
            <ProductGroupListDialog
                showAddEditProductGroupsflag={componentState.showAddEditProductGroupsflag}
                onClickSubmit={onClickAddEditProductGroupsSubmit}
                onClickCancel={onClickAddEditProductGroupsCancel}
                discountProductGroups={componentState.discountProductGroups}
            />
        }

        {
            componentState.showAddEditCustomersflag &&
            <CustomerListDialog
                showAddEditCustomersflag={componentState.showAddEditCustomersflag}
                onClickSubmit={onClickAddEditCustomersSubmit}
                onClickCancel={onClickAddEditCustomersCancel}
                discountCustomers={componentState.discountCustomers}
            />
        }

        <AlertDialog
            alertState={{ ...componentState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default CreateUpdateDiscount;

const FlexLayout = styled.div`
    display:flex;
    align-items: center;

    p{
        margin-bottom: 0;
        margin-left: 10px;
    }
`;