import styled from "styled-components";
import { Colors } from "../styles/colors";
import { FaTrashCan } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";

const DisplayImageBtn = styled.div`
    width:100%;
    height:${props => props.layoutHeight ? props.layoutHeight : "250px"};
    border-radius:4px;
    overflow: hidden;
    border: ${Colors.displayImageLayoutBorder};
    
    img{
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: top center;
    }
`;

const EditDeleteBtns = styled.div`
    position:absolute;
    bottom:5px;
    right:5px;
    display:none;

    button{
        height:auto !important;
        background:${Colors.displayImageLayoutBtnBackgroundColor};
        margin-top:20px;
        border-radius: 2px;
        color:${Colors.displayImageLayoutBtnTextColor};
        border:none;
        padding:${props=> props.actionBtnPadding ? props.actionBtnPadding : "12px"};
        border-radius:4px;

        svg{
            font-size:${props=> props.actionBtnFontSize ? props.actionBtnFontSize : "20px"};
        }

        &:hover{
            background:${Colors.displayImageLayoutBtnBackgroundHoverColor};

            svg{
                color:${Colors.displayImageLayoutBtnHoverTextColor};
            }
        }
    }
    
`;

const DeleteImageBtnWrapper = styled.div`
    position:relative;

    &:hover ${EditDeleteBtns}{
        display:block;
    }
`;

const DeleteIconBtn = styled.button`
    margin-left: 5px;
`;

const EditIconBtn = styled.button`
    
`;

const DisplayImageLayoutBtn = ({ showImageLibrary, imageSrc, deleteImage, layoutHeight, 
    actionBtnFontSize, actionBtnPadding, showEdit }) => {
    return <DeleteImageBtnWrapper>
        <DisplayImageBtn layoutHeight = {layoutHeight}>
            <img src={imageSrc} />
        </DisplayImageBtn>
        <EditDeleteBtns actionBtnFontSize = {actionBtnFontSize} actionBtnPadding = {actionBtnPadding}>
            {
                showEdit !== "N" &&
                <EditIconBtn onClick={showImageLibrary}><FaEdit /></EditIconBtn>
            }
            <DeleteIconBtn onClick={deleteImage}><FaTrashCan /></DeleteIconBtn>
        </EditDeleteBtns>
    </DeleteImageBtnWrapper>
}
export default DisplayImageLayoutBtn;