import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegUser } from "react-icons/fa6";
import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { isEmptyVariable } from '../../utils/utilFunctions';
import { ATTACHMENT_PATH } from '../../utils/apiEndPoints';

const DropdownWrap = styled.div`
    .dropdown .btn:hover{
      background:none ;
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
      background:none ;
    }
    .dropdown-item.active, .dropdown-item:active{
        background: ${Colors.dropdownItemBgSelected};
        color:${Colors.paragraphColor};
    }
    button{
      display:flex;
      align-items:center;
      background:none;
      border:none;
      padding:0;
      
      span{
        color:${Colors.paragraphColor};
        margin-left:5px;
        font-size:13px;
        margin-bottom:0;
      }
    }
    .dropdown-toggle::after{
      content:none;
    }

    .dropdown-menu.show{
      padding:0;
      min-width: 130px;
    }
    .dropdown-item{
      padding:10px 15px;
      font-size:13px;
      display:flex;
      align-items:center;

      svg{
        font-size:20px;
        flex-shrink: 0;
        margin-right:8px;
        color:${Colors.paragraphColor};
      }
    }
`;
const UserWrap = styled.div`
    background:${Colors.profileIconGreyColor};
    width:30px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:100%;

    svg{
      color:${Colors.themeColorPrimary};
      font-size:16px;
    }

    img{
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius:100%;
    }
`
const ProfileDropdown = (props) => {
    const { profilePic, dropdownLabel, dropdownList } = props;
    return (
        <DropdownWrap>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    <UserWrap>
                        {
                            isEmptyVariable(profilePic) &&
                            <FaRegUser />
                        }
                        {
                            !isEmptyVariable(profilePic) &&
                            <img src={ATTACHMENT_PATH + profilePic} />
                        }
                    </UserWrap>
                    <span>{dropdownLabel}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        (dropdownList) ?
                            dropdownList.map((item, idx) => {
                                return (
                                    <Dropdown.Item
                                        key={"profile" + idx}
                                        href={item.optionUrl}>{item.optionIcon}{item.option}
                                    </Dropdown.Item>
                                )
                            })
                            :
                            "Account Deactivated"
                    }
                </Dropdown.Menu>
            </Dropdown>
        </DropdownWrap>
    );
}

export default ProfileDropdown;