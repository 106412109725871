import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactCreatableSelect from '../../components/dropdowns/reactCreatableSelect';
import ImageLibrary from '../../components/imageLibraryDialog';
import DatetimePickerComp from '../../components/input/datetimePicker';
import RichTextEditorField from '../../components/input/richTextEditor';
import AddImageButton from '../../components/randomComponents/addImageButton';
import DisplayImageLayoutBtn from '../../components/randomComponents/displayImageLayoutBtn';
import { FieldGroupAddImageWrapper, FieldGroupWrapper, FormWrapper } from '../../components/styles/addEditFormStyles';
import { BackButton, ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper } from '../../components/styles/listingHeaderStyles';
import { useAuth } from '../../hooks/useAuth';
import { ADD_BLOG, ATTACHMENT_PATH, GET_BLOG_MASTER_DATA, UPDATE_BLOG } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, convertDateTimeObjToString, getCurrentLocalDateAndTimeDateObj, getDateAndTimeObjFromStr, ifEmptyReturnStr, isEmptyArray, isEmptyObject, isEmptyVariable } from '../../utils/utilFunctions';

const initialState = {
    blogTitle: "",
    blogDescription: "",
    blogStatus: "",
    seoTitle: "",
    seoDescription: "",
    urlHandle: "",
    blogDate: getCurrentLocalDateAndTimeDateObj(),
    selectedAuthor: {},
    selectedCategories: [],
    imageAttachmentObj: {

    },
    errors: {
        blogTitle: "",
        blogDescription: "",
        blogStatus: "",
        seoTitle: "",
        seoDescription: "",
        urlHandle: "",
    },
    apiErr: "",
}

const CreateUpdateBlog = (props) => {
    const { addEditBlogComp, onClickSubmit, onClickCancel } = props;

    const [componentState, setComponentState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditBlogComp.showAddEditComp) {
            getMasterData();
        }
    }, [addEditBlogComp.showAddEditComp])

    const getMasterData = async () => {
        try {
            let response = await callAuthApi(GET_BLOG_MASTER_DATA, {
                blogId: addEditBlogComp.blogId
            }, user, logout);

            if (addEditBlogComp.isEdit) {
                const { blogTitle, blogDescription, blogStatus, seoTitle,
                    seoDescription, urlHandle, blogAuthorId, blogAuthorName,
                    productAttachmentId, productAttachmentPath,
                    categoryList: selectedCategories, blogDate } = response.data.blogDetails;

                setComponentState({
                    ...initialState,
                    blogTitle: blogTitle,
                    blogDescription: blogDescription,
                    blogStatus: blogStatus,
                    seoTitle: seoTitle,
                    seoDescription: seoDescription,
                    urlHandle: urlHandle,
                    blogDate: getDateAndTimeObjFromStr(blogDate),
                    selectedAuthor: {
                        blogAuthorId: blogAuthorId,
                        blogAuthorName: blogAuthorName
                    },
                    selectedCategories: selectedCategories,
                    imageAttachmentObj: {
                        productAttachmentId: productAttachmentId,
                        productAttachmentPath: productAttachmentPath
                    },
                    authors: response.data.authors.result.map(author => ({
                        blogAuthorId: author.blogAuthorId + "",
                        blogAuthorName: author.blogAuthorName
                    })),
                    categories: response.data.categories.result.map(category => ({
                        blogCategoryId: category.blogCategoryId + "",
                        blogCategoryName: category.blogCategoryName
                    }))
                });
            } else {
                setComponentState({
                    ...initialState,
                    authors: response.data.authors.result.map(author => ({
                        blogAuthorId: author.blogAuthorId + "",
                        blogAuthorName: author.blogAuthorName
                    })),
                    categories: response.data.categories.result.map(category => ({
                        blogCategoryId: category.blogCategoryId + "",
                        blogCategoryName: category.blogCategoryName
                    }))
                });
            }
            inputRef.current.focus();
        } catch (alertErr) {
            setComponentState({
                ...initialState,
                apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            });
        }
    }

    const onChangeDateTime = (value) => {
        setComponentState({
            ...componentState,
            blogDate: value
        })
    }

    const inputChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.value
        })
    }

    const handleUrlHandle = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, '-');
        setComponentState({
            ...componentState,
            [e.target.name]: newValue.toLowerCase()
        })
    }

    const editorOnChange = (value) => {
        setComponentState({
            ...componentState,
            blogDescription: value
        })
    }

    const blogStatusDropdownItemClick = (option) => {
        setComponentState({
            ...componentState,
            blogStatus: option.blogStatus
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.blogTitle)) {
            hasErrors = true;
            errors.blogTitle = "Please enter the blog title"
        }

        if (isEmptyVariable(componentState.blogDescription)) {
            hasErrors = true;
            errors.blogDescription = "Please enter the description"
        }

        if (isEmptyVariable(componentState.seoTitle)) {
            hasErrors = true;
            errors.seoTitle = "Please enter the SEO title"
        }

        if (isEmptyVariable(componentState.seoDescription)) {
            hasErrors = true;
            errors.seoDescription = "Please enter the SEO description"
        }

        if (isEmptyVariable(componentState.urlHandle)) {
            hasErrors = true;
            errors.urlHandle = "Please enter the URL handle"
        }

        if (isEmptyObject(componentState.selectedAuthor)) {
            hasErrors = true;
            errors.blogAuthor = "Please enter the author"
        }

        // if (isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)) {
        //     hasErrors = true;
        //     errors.productAttachmentId = "Please select an image"
        // }

        setComponentState({
            ...componentState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const showImageLibrary = () => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: true
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: false
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            setComponentState({
                ...componentState,
                imageAttachmentObj: selectedImages[0],
                showImageLibraryDialog: false
            })
        }
    }

    const deleteImage = () => {
        setComponentState({
            ...componentState,
            imageAttachmentObj: {},
        })
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditBlogComp.isEdit) {
                    await updateBlog();
                } else {
                    await createNewBlog();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setComponentState({
                    ...componentState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

            //     setTimeout(() => {
            //         setComponentState({
            //             ...componentState,
            //             apiErr: ""
            //         })
            //     }, WARNING_MSG_TIMEOUT);
            }
        } else {
            // setTimeout(() => {
            //     setComponentState({
            //         ...componentState,
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    const onSelectAuthor = (selectedOption) => {
        setComponentState({
            ...componentState,
            selectedAuthor: { ...selectedOption }
        })
    }

    const onSelectCategory = (selectedOptions) => {
        setComponentState({
            ...componentState,
            selectedCategories: selectedOptions.map(option => {
                if (option.__isNew__) {
                    return {
                        blogCategoryId: "",
                        blogCategoryName: option.label
                    }
                }
                return option;
            })
        })
    }

    const getAuthorsStr = () => {
        if (!isEmptyVariable(componentState.selectedAuthor?.blogAuthorId)) {
            return JSON.stringify(componentState.selectedAuthor)
        } else if (componentState.selectedAuthor?.__isNew__) {
            return JSON.stringify({
                blogAuthorId: "",
                blogAuthorName: componentState.selectedAuthor.label
            })
        } else {
            return "";
        }
    }

    const getCategoriesStr = () => {
        if (!isEmptyArray(componentState.selectedCategories)) {
            let cats = componentState.selectedCategories.map(category => {
                if (category.__isNew__) {
                    category.blogCategoryName = category.label
                }
                return category;
            })
            return JSON.stringify(cats);
        } else {
            return "";
        }
    }

    //API Calls
    const createNewBlog = async () => {
        await callAuthApi(ADD_BLOG, {
            blogTitle: componentState.blogTitle,
            blogDescription: componentState.blogDescription,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            blogDate: convertDateTimeObjToString(componentState.blogDate),
            blogAuthor: getAuthorsStr(),
            blogCategories: getCategoriesStr(),
        }, user, logout)
    }

    const updateBlog = async () => {
        await callAuthApi(UPDATE_BLOG, {
            blogId: addEditBlogComp.blogId,
            blogTitle: componentState.blogTitle,
            blogDescription: componentState.blogDescription,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            blogDate: convertDateTimeObjToString(componentState.blogDate),
            blogAuthor: getAuthorsStr(),
            blogCategories: getCategoriesStr(),
        }, user, logout);
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{addEditBlogComp.isEdit ? "Edit Blog" : "Add Blog"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={8}>
                    <FieldGroupWrapper>
                        <label>Title*</label>
                        <input
                            value={componentState.blogTitle}
                            name="blogTitle"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.blogTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.blogTitle}</span>
                        }
                        <label>Description*</label>
                        <RichTextEditorField
                            value={componentState.blogDescription}
                            editorOnChange={editorOnChange}
                            editorHeight={"400px"}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.blogDescription) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.blogDescription}</span>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Search Engine</h6>
                        <label>SEO Title*</label>
                        <input
                            value={componentState.seoTitle}
                            name="seoTitle"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoTitle.length > 60 ? "red" : "#555555" }}>
                            {componentState.seoTitle.length + " of 60 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.seoTitle}</span>
                        }

                        <label>SEO Description*</label>
                        <textarea
                            value={componentState.seoDescription}
                            name="seoDescription"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoDescription.length > 320 ? "red" : "#555555" }}>
                            {componentState.seoDescription.length + " of 320 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoDescription) &&
                            <span className="err-span m-b-10">{componentState.errors.seoDescription}</span>
                        }

                        <label>URL Handle*</label>
                        <input
                            value={componentState.urlHandle}
                            name="urlHandle"
                            onChange={handleUrlHandle}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.urlHandle) &&
                            <span className="err-span m-b-10">{componentState.errors.urlHandle}</span>
                        }

                    </FieldGroupWrapper>
                </Col>

                <Col md={4}>
                    <FieldGroupAddImageWrapper>
                        <h6>Featured Image</h6>
                        {
                            isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)
                                ?
                                <AddImageButton
                                    text="Add Image"
                                    showImageLibrary={showImageLibrary}
                                />
                                :
                                <DisplayImageLayoutBtn
                                    showImageLibrary={showImageLibrary}
                                    deleteImage={deleteImage}
                                    imageSrc={ATTACHMENT_PATH + componentState.imageAttachmentObj.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                />
                        }
                        {
                            !isEmptyVariable(componentState.errors?.productAttachmentId) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productAttachmentId}</span>
                        }
                    </FieldGroupAddImageWrapper>

                    <FieldGroupWrapper>
                        <h6>Blog Organisation</h6>
                        <label>Author*</label>
                        <ReactCreatableSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"authorlist"}
                            options={componentState.authors}
                            isMulti={false}
                            placeholder={"Select Author"}
                            labelField={"blogAuthorName"}
                            valueField={"blogAuthorId"}
                            onChange={onSelectAuthor}
                            value={Object.keys(componentState.selectedAuthor).length === 0 ? null : componentState.selectedAuthor}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.blogAuthor) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.blogAuthor}</span>
                        }

                        <label>Category</label>
                        <ReactCreatableSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            closeMenuOnSelect={false}
                            reactSelectName={"cateforylist"}
                            options={componentState.categories}
                            isMulti={true}
                            placeholder={"Select Categories"}
                            labelField={"blogCategoryName"}
                            valueField={"blogCategoryId"}
                            onChange={onSelectCategory}
                            value={isEmptyArray(componentState.selectedCategories) ? null : componentState.selectedCategories}
                        />

                        <label>Publish Date</label>
                        <DatetimePickerComp
                            value={componentState.blogDate}
                            onChange={onChangeDateTime}
                        />

                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />
    </>
}

export default CreateUpdateBlog;