import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';
import { Others } from '../../components/styles/others';

export const LoginSection = styled.section`
    background: ${Colors.loginBackground};
    min-height: 100vh;
`;

export const LoginWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh - 90px);
`;

export const LoginCol = styled.div`
    max-width:500px;
    min-width:450px;
    margin:auto;
    text-align:center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 25px;
    padding:40px;
    background-color: ${Colors.whiteColor};

    p{
        font-size:14px;
        opacity:.85;
        margin-bottom: 30px;
    }

    input{
        width:100%;
        margin-bottom:20px;
        padding:10px 15px;
        border-radius:0;
        background:#fff;
        border-radius: 4px;
    }

    input[type="email"]{
        
    }

    input[type="password"]{

    }

    button{
        border:none;
        width:100%;
        padding:10px 15px;
        text-align:center;
        justify-content: center;
        color:#fff;
        text-transform:uppercase;
        margin-bottom:15px;
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;

        svg{
            margin-left:10px;
        }
    }

    .ortext{
        margin-bottom:15px;
    }

    .err-span{
        margin-top:-15px;
        margin-bottom:8px;
    }
    .api-err-span{
        margin-top: -10px;
        margin-bottom:8px;
    }

    a{
        text-decoration:underline;
    }

`;

export const GoogleBtnWrap = styled.div`
    button{
        box-shadow: ${Others.btnBoxShadow};
        color: ${Colors.paragraphColor};
        text-decoration: none;
        padding:10px 20px;
        display:inline-block;
        margin-bottom:15px;
        width:auto;
        background:none;
        border-radius: 4px;
        border:1px solid transparent;

        &:hover{
            box-shadow:none;
            background:none;
            border:1px solid ${Colors.inputBorderColor};
        }
    }
`;

export const ForgotPassColWrap = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:0px;

    a{
        display:inline-block;
        margin-bottom:10px;
        text-decoration:none;
        color:${Colors.paragraphColor};


        &:last-child{
            margin-bottom:0;
        }
        &:hover{
            text-decoration:underline;
        }
    }
`;


export const LogoCol = styled.div`
    text-align: center;
    margin-bottom: 10px;
    img{
        max-width: 150px;
    }
`;