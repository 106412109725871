import { LoginWrapper, LoginCol, GoogleBtnWrap, ForgotPassColWrap, LoginSection, LogoCol } from './login.style';
import { useAuth } from '../../hooks/useAuth';
import { GetLoginReducer } from './reducer';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalStyle from '../../components/styles/global-styles';
import { callApi, isEmptyVariable } from '../../utils/utilFunctions';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { ThreeDots } from 'react-loader-spinner';
import {CHECK_LOGIN} from '../../utils/apiEndPoints';

const LoginPage = () => {
    const [state, dispatch] = GetLoginReducer();
    const { login, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user !== null) {
            navigate("/dashboard");
        }
    }, []);

    const validate = () => {
        let hasErrors = false;
        let errors = {}

        if (isEmptyVariable(state.email)) {
            hasErrors = true;
            errors.emailErr = "Please enter the email"
        }

        if (!isEmptyVariable(state.email)) {
            var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!reg.test(state.email)) {
                hasErrors = true;
                errors.emailErr = "Please enter the valid email"
            }
        }

        if (isEmptyVariable(state.password)) {
            hasErrors = true;
            errors.passwordErr = "Please enter the password"
        }

        if (hasErrors) {
            dispatch({ type: "SET_VALIDATION_ERRORS", errors: { ...errors } });
        }

        return hasErrors;
    }

    const onLoginSubmit = async () => {
        if (!validate()) {
            dispatch({ type: "SET_LOGIN_LOADER_FLAG", flag: true });

            let response = await callApi(CHECK_LOGIN, {
                email: state.email,
                password: state.password
            });
            dispatch({ type: "SET_LOGIN_LOADER_FLAG", flag: false });

            if (response.data.responseCode === 0) {
                //Now lets set the user object
                await login(response.data.data);
                navigate("/dashboard", { replace: true });
            } else {
                dispatch({ type: "SET_VALIDATION_ERRORS", errors: { apiErr: response.data.responseMessage } });

                //clear the validation errros
                setTimeout(() => {
                    dispatch({ type: "SET_VALIDATION_ERRORS", errors: {} });
                }, WARNING_MSG_TIMEOUT);
            }
            
        } else {
            //clear the validation errros
            setTimeout(() => {
                dispatch({ type: "SET_VALIDATION_ERRORS", errors: {} });
            }, WARNING_MSG_TIMEOUT);
        }
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onLoginSubmit();
        }
    }
    return (
        <LoginSection>
            <LoginWrapper>
                <GlobalStyle />
                <Container>
                    <Row>
                        <Col md={12}>
                            <LoginCol>
                                <LogoCol>
                                    <img src="/images/sl-logo-center.png"/>
                                </LogoCol>
                                <p>Login</p>
                                <input
                                    type='email'
                                    placeholder='Email'
                                    value={state.email}
                                    onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "email", fieldValue: e.target.value })}
                                />
                                {
                                    !isEmptyVariable(state.errors.emailErr) &&
                                    <span className="err-span">{state.errors.emailErr}</span>
                                }
                                <input
                                    type='password'
                                    placeholder='Password'
                                    value={state.password}
                                    onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "password", fieldValue: e.target.value })}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                                {
                                    !isEmptyVariable(state.errors.passwordErr) &&
                                    <span className="err-span">{state.errors.passwordErr}</span>
                                }
                                <button onClick={onLoginSubmit} disabled={state.isLoginLoading}>
                                    Login
                                    {
                                        state.isLoginLoading &&
                                        <ThreeDots
                                            height="20"
                                            width="30"
                                            radius="9"
                                            color="#fff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperclassName=""
                                            visible={true}
                                        />
                                    }
                                </button>
                                {
                                    !isEmptyVariable(state.errors.apiErr) &&
                                    <span className="err-span api-err-span">{state.errors.apiErr}</span>
                                }
                                
                                <ForgotPassColWrap>
                                    <Link to={"/forgotpassword"}>Forgot Password?</Link>
                                </ForgotPassColWrap>
                            </LoginCol>
                        </Col>
                    </Row>
                </Container>
            </LoginWrapper>
        </LoginSection>
    )
}
export default LoginPage;